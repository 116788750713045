<template>
  <div>
    <!--
    <v-col sm="2">
      <v-btn @click="$emit('remove', id)"
        ><v-icon red>mdi-delete</v-icon></v-btn
      >
    </v-col>
-->
    <v-col md="6">
      <v-btn @click="showHide(configId)">
        <div v-show="showConfig.show">Replier la configuration</div>
        <div v-show="!showConfig.show">Afficher la configuration</div>
      </v-btn>
    </v-col>

    <v-row v-show="showConfig.show">
      <v-col offset-md="1" md="3">
        <v-col sm="2">
          <v-btn @click="$emit('remove', id)"
            ><v-icon red>mdi-delete</v-icon></v-btn
          >
        </v-col>

        <v-autocomplete
          :items="productColors"
          :id="'color' + configId"
          item-text="color"
          item-value="colorId"
          label="Couleur"
          clearable
          hide-details
          hide-selected
          v-model="selectedColor"
          @input="onProductColorChange"
        ></v-autocomplete>
      </v-col>

      <v-col
        offset-md="1"
        md="12"
        v-if="this.value.color !== null && this.value.color.trim() !== ''"
      >
        <vue-upload-multiple-image
          :data-images="images"
          v-model="value.images"
          name="images"
          dragText="Drag image"
          browseText="Browse image"
          primaryText="Primary image"
          markIsPrimaryText="Set default image"
          popupText="Description default image"
          dropText="Drag and drop"
          @upload-success="uploadImageSuccess"
          @before-remove="beforeRemove"
          @edit-image="editImage"
          @data-change="dataChange"
          :idUpload="configId"
        >
        </vue-upload-multiple-image>
      </v-col>

      <v-col offset-md="1" sm="12">
        <v-btn @click="addPrice" class="ma-2" color="primary" dark
          >Add price<v-icon dark right>mdi-plus</v-icon></v-btn
        >
      </v-col>

      <v-col md="12" v-for="price in prices" v-bind:key="price.id">
        <Price
          :show="showPrice"
          :id="price.id"
          @remove="removePrice"
          :value="price"
          :objectType="objectType"
          @input="
            (newPrice) => {
              price = newPrice;
            }
          "
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { firebase } from "@firebase/app";
import Price from "./Price.vue";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import FunctionsUtils from "./../../../utils/functions";
import ColorsUtils from "./../../../utils/colors";
import NodesName from "./../../../utils/nodesName";

export default {
  components: {
    Price,
    VueUploadMultipleImage,
  },
  data() {
    return {
      configId: null,
      prices: [],
      images: [],
      showConfig: { id: null, show: false },
      showPrice: false,
      productColors: [],
      selectedColor: null,
      smartphoneComponent: NodesName.SMARTPHONES,
      tabletteComponent: NodesName.TABLETTE,
      computerComponent: NodesName.ORIDNATEURS,
      videoGamesComponent: NodesName.CONSOLE,
      imprimantesScannersComponent: NodesName.IMPRIMANTES_SCANNERS,
    };
  },
  methods: {
    addPrice() {
      this.prices.unshift({
        id: FunctionsUtils.create_UUID(),
        price: "",
        storageCapacity: "",
      });
      this.value.prices = this.prices;
      this.showPrice = true;
    },
    removePrice(idToRemove) {
      if (confirm("Are you sure ?")) {
        this.prices = this.prices.filter((price) => {
          return price.id !== idToRemove;
        });
        this.value.prices = this.prices;
      }
    },
    uploadImageSuccess(formData, index, fileList) {
      var imageName = fileList[index].name;
      const ext = imageName.slice(imageName.lastIndexOf("."));
      const file = this.convertImagePathToFile(fileList[index]);

      firebase
        .storage()
        .ref(
          "modelProduct/" +
            this.objectType +
            "/" +
            this.objectMark +
            "/" +
            this.id +
            "/" +
            this.value.color +
            "/" +
            imageName
        )
        .put(file)
        .then((response) => {
          firebase
            .storage()
            .ref(
              "modelProduct/" +
                this.objectType +
                "/" +
                this.objectMark +
                "/" +
                this.id +
                "/" +
                this.value.color +
                "/" +
                imageName
            )
            .getDownloadURL()
            .then((url) => {
              this.value.images.push(url);
            })
            .catch(function (error) {
              // Handle any errors
            });
        });
    },
    convertImagePathToFile(element) {
      var byteString = atob(element.path.split(",")[1]);
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      var blob = new Blob([ia], { type: "image/jpeg" });
      return new File([blob], element.name);
    },
    beforeRemove(index, done, fileList) {
      var r = confirm("remove image");
      if (r == true) {
        var indexInArrayImages = this.value.images.indexOf(
          fileList[index].path
        );
        if (index > -1) {
          this.value.images.splice(indexInArrayImages, 1);
        }

        done();
      }
    },
    editImage(formData, index, fileList) {
      //console.log('edit data', formData, index, fileList)
    },
    dataChange(data) {
      //console.log(data)
    },
    showHide(id) {
      this.showConfig.id = id;
      this.showConfig.show
        ? (this.showConfig.show = false)
        : (this.showConfig.show = true);
    },
    onProductColorChange(event) {
      this.selectedColor = event;
      this.value.color = this.productColors.find(
        (x) => x.colorId === event
      ).color;
    },
  },
  props: {
    id: {
      type: String,
    },
    value: {
      type: Object,
      required: true,
    },
    objectType: {
      type: String,
    },
    objectMark: {
      type: String,
    },
    show: {
      type: Boolean,
    },
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    },
  },
  created() {
    this.configId = this.value.id;

    this.value.images.forEach((image) => {
      this.images.push({ name: "", path: image, highlight: 1, default: 1 });
    });

    this.prices = this.value.prices;
    this.showConfig.show = this.show;

    for (let key in ColorsUtils) {
      if (isNaN(Number(key))) {
        this.productColors.push({
          colorId: key,
          color: ColorsUtils[key],
        });
      }
    }
  },
  mounted() {
    this.selectedColor = FunctionsUtils.getKeyByValue(
      ColorsUtils,
      this.value.color
    );
  },
};
</script>
