export default Object.freeze({
    PURPLE               : 'Mauve',
    YELLOW               : 'Jaune',
    GREEN                : 'Vert',
    BLACK                : 'Noir',
    WHITE                : 'Blanc',
    RED                  : 'Red',
    SPACE_GRAY           : 'Gris sidéral',
    SILVER               : 'Argent',
    GOLD                 : 'Or',
    MIDNIGHT_GREEN       : 'Vert nuit',
    GOLD_ROSE            : 'Or rose',
    BLUE                 : 'Bleu',
    CORAL                : 'Coral'
})