<template>
  <div>
    <v-container>
      <v-row no-gutters>
        <v-col md="12">
          <v-card class="mx-auto">
            <v-card-text>
              <v-card-text>
                <div>
                  <vuetify-money
                    v-model="value.price"
                    v-bind:label="label"
                    v-bind:placeholder="placeholder"
                    v-bind:readonly="readonly"
                    v-bind:disabled="disabled"
                    v-bind:outlined="outlined"
                    v-bind:clearable="clearable"
                    v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
                    v-bind:options="options"
                    required
                  />
                </div>
                <v-text-field
                  label="Capacité de stockage"
                  v-model="value.storageCapacity"
                  required
                ></v-text-field>
                <div v-if="objectType == this.computerComponent">
                  <v-text-field
                    label="RAM installé"
                    v-model="value.installedRAM"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Carte graphique"
                    v-model="value.graphicCard"
                    required
                  ></v-text-field>
                  <v-text-field
                    label="Processeur"
                    v-model="value.processor"
                    required
                  ></v-text-field>
                </div>
              </v-card-text>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import NodesName from "./../../../utils/nodesName";

export default {
  data() {
    return {
      testevalue: null,
      label: "Prix",
      placeholder: " ",
      readonly: false,
      disabled: false,
      outlined: true,
      clearable: true,
      valueWhenIsEmpty: "",
      options: {
        locale: "fr-TN",
        prefix: "TND",
        suffix: "",
        length: 11,
        precision: 3,
      },

      showPrice: { id: null, show: false },
      computerComponent: NodesName.ORIDNATEURS,
    };
  },
  methods: {
    showHide(id) {
      this.showPrice.id = id;
      this.showPrice.show
        ? (this.showPrice.show = false)
        : (this.showPrice.show = true);
    },
  },
  props: {
    id: {
      type: String,
    },
    value: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
    },
    objectType: {
      type: String,
    },
  },
  watch: {
    value() {
      this.$emit("input", this.value);
    },
  },
  created() {
    this.showPrice.show = this.show;
  },
};
</script>
